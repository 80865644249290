// UserAvatar.jsx
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextUserAvatar from '../container/TextUserAvatar.js';
import LazyImage from '../component/LazyImage.jsx';
import StatefulImage from '../component/StatefulImage.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';
import AvatarPlaceholder from '../../img/placeholder-avatar-cube-60.svg';

const DEFAULT_SRCSET = [
  MediaAssetFormat.AVIF,
  MediaAssetFormat.WEBP,
  MediaAssetFormat.JPG,
]
  .map(format => {
    return [
      { size: 256, resolution: '1x' },
      { size: 256, resolution: '2x' },
    ].map(size => ({ ...size, ext: format }));
  })
  .flatMap(a => a);

export const UserAvatar = ({
  id = '',
  meId = '',
  size,
  srcSet = DEFAULT_SRCSET,
  imageStyle,
  username = '',
}) => {
  const nextTick = useRef(null);
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    nextTick.current = setTimeout(() => {
      setIsServer(false);
    }, 0);
    return () => {
      clearTimeout(nextTick.current);
    };
  });
  if (!id) {
    // Render same structure to reduce DOM mutations
    return (
      <StyledUserAvatar size={size}>
        <StatefulWrapper>
          <TextUserAvatar userId={id} avatarSize={size} />
        </StatefulWrapper>
      </StyledUserAvatar>
    );
  }

  const imageSize = srcSet[0]?.size || '64';

  const imageSet = srcSet.reduce((acc, set) => {
    const arr = acc[set.ext] || [];

    const url = getUserPicture({
      userId: id,
      meId,
      size: set.size,
      format: set.ext,
    });

    const src = `${url.href} ${set.resolution}`;
    if (arr.includes(src)) {
      return acc;
    }

    return {
      ...acc,
      [set.ext]: arr.concat([src]),
    };
  }, {});

  const jpgImageSet = Object.keys(imageSet)
    .filter(key => key === 'jpg')
    .map(key => imageSet[key])
    .join(', ');

  const shouldHydrate = isServer || !window.__IS_HYDRATED__;

  const renderHydrateAvatar = () => (
    <WithIntersectionObserver>
      {({ isIntersecting }) => (
        <HydrationBoundary
          wrapper={<StyledUserAvatar size={size} />}
          shouldHydrate={isIntersecting}
        >
          {/* use key={id} to force remount, for resetting StatefulImage state, if id changed */}
          <StatefulImage key={id}>
            <StatefulWrapper data-key="error">
              <TextUserAvatar userId={id} avatarSize={size} />
            </StatefulWrapper>
            <StatefulWrapper data-key="loading">
              <DefaultImage
                src={AvatarPlaceholder}
                style={imageStyle}
                width="60"
                height="60"
              />
            </StatefulWrapper>
            <Picture
              data-key="target"
              srcSet={jpgImageSet}
              src={imageSet.jpg[0].split(' ')[0]}
              alt={`${username}'s avatar`}
              width={imageSize}
              height={imageSize}
            >
              {Object.keys(imageSet).map(ext => {
                const set = imageSet[ext];
                if (ext === 'jpg') {
                  return null;
                }
                return (
                  <source
                    key={ext}
                    type={`image/${ext}`}
                    srcSet={set.join(', ')}
                    width={imageSize}
                    height={imageSize}
                  />
                );
              })}
              <img
                style={{
                  borderRadius: '50%',
                  ...imageStyle,
                }}
                src={imageSet.jpg[0].split(' ')[0]}
                srcSet={jpgImageSet}
              />
            </Picture>
          </StatefulImage>
        </HydrationBoundary>
      )}
    </WithIntersectionObserver>
  );

  const renderAvatar = () => (
    <StyledUserAvatar size={size}>
      {/* use key={id} to force remount, for resetting StatefulImage state, if id changed */}
      <StatefulImage key={id}>
        <StatefulWrapper data-key="error">
          <TextUserAvatar userId={id} avatarSize={size} />
        </StatefulWrapper>
        <StatefulWrapper data-key="loading">
          <DefaultImage
            src={AvatarPlaceholder}
            style={imageStyle}
            width="60"
            height="60"
          />
        </StatefulWrapper>
        <LazyImage
          data-key="target"
          srcSet={jpgImageSet}
          src={imageSet.jpg[0].split(' ')[0]}
          threshold={0.25}
          style={{
            borderRadius: '50%',
            ...imageStyle,
          }}
          alt={`${username}'s avatar`}
          width={imageSize}
          height={imageSize}
        >
          {Object.keys(imageSet).map(ext => {
            const set = imageSet[ext];
            if (ext === 'jpg') {
              return null;
            }
            return (
              <source
                key={ext}
                type={`image/${ext}`}
                srcSet={set.join(', ')}
                width={imageSize}
                height={imageSize}
              />
            );
          })}
        </LazyImage>
      </StatefulImage>
    </StyledUserAvatar>
  );

  return shouldHydrate ? renderHydrateAvatar() : renderAvatar();
};

UserAvatar.propTypes = {
  id: PropTypes.string,
  meId: PropTypes.string,
  size: PropTypes.number.isRequired,
  imageStyle: PropTypes.object,
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number,
      resolution: PropTypes.string,
      ext: PropTypes.string,
    })
  ),
  username: PropTypes.string,
};

const StyledUserAvatar = styled.div`
  position: relative;
  ${({ size }) => `
  width: ${size}px;
  height: ${size}px;
`}
`;

const StatefulWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const DefaultImage = styled.img`
  border-radius: 50%;
`;

const Picture = styled.picture`
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default UserAvatar;
